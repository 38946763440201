* {
   margin: 0;
   padding: 0;
   width: 100%;
   box-sizing: border-box;
   overflow-x: hidden;
}

div.page-title{
      text-align: center;
}


.home .about .contact {
   margin-top: 3rem;   
   padding: 1rem 15rem;


}


@include devices(mobile) {
   *{
      overflow-y: auto;
   }

}