$mobilelg: 480px;
$tabletlg: 768px;
$laptoplg: 1024px;
$desktoplg: 1280px;


@mixin devices ($breakpoint) {
   @if $breakpoint == mobile {
      @media (max-width: $mobilelg) { @content };
   } @else if $breakpoint == tablet {
      @media (min-width: $tabletlg) { @content }; 
   } @else if $breakpoint == laptop {
      @media (min-width: $laptoplg) { @content };
   } @else if $breakpoint == desktop {
      @media (min-width: $desktoplg) { @content };
   }
}