.contact {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    padding: 1rem;
    background-color: $nb-dark-blue;
    color: #ffffff;
    margin-bottom: 3rem;
    gap:3rem;
    @include devices(mobile){
        grid-template-columns: auto;
        grid-template-rows: auto auto;
    }
    .contact-info {
        padding: 2rem;
        font-size: 1.3rem;
        // p {
        //     font-size: 1.3rem;
        // }
    }
    .contact-form {
        padding: 3rem;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: .75rem;
        form {
            font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            
            > * {
                margin-bottom: 1rem;
            }
            label {
                font-size: 1.2rem;
                font-weight: 800;
            }

            input {
                padding: .55rem;
                background-color: transparent;
                color: $nb-green;
                border: none;
                border-bottom: rgba(255, 255, 255, 1) solid 1px;
                &::placeholder {
                    color: rgba(255, 255, 255, 0.3);
                }

                &[type="submit"] {
                    background-color: transparent;
                    border-radius: .7rem;
                    border: solid 2px #fff;
                    width: 25%;
                    padding: .65rem;
                    font-size: 1.1rem;
                    color: #fff;
                    &:hover{
                        background-color: $nb-green;
                    }
                    @include devices(mobile) {
                        width:100%;
                    }
                }
            }

            textarea {
                padding: .55rem;
                background-color: transparent;
                border:none;
                border-bottom: solid 2px #ffffff;
                &::placeholder {
                    color: rgba(255, 255, 255, 0.3);
                }

            }
        }
    }
}

