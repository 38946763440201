.about {
   margin-top: 5rem;
   padding: 2rem 8rem;
   @include devices(mobile) {
      margin-top: 1rem;
      margin-bottom: 3rem;
   }
  

   &-item {
      color: #fff;
     
      &-contents {       

         &-bio {
            background-color: $nb-dark-blue;
            padding: 2rem;
            // border-radius: .75rem;
            margin-bottom: 1rem;
         }

         &-details {
            display: flex;
            gap: 1rem;
            > .icons {
               display: flex;
               background-color: #ededed;
               padding: 1.5rem;
               align-items: center;
               // width: fit-content;
               @include devices(mobile) {
                  &:nth-child(1) {
                     display: none;
                  }
               }
             

               .icon-content {
                  display: flex;
                  gap: 1rem;
                  @include devices(mobile){
                     text-align: center;
                  }
                  
                  img{
                     width: 2rem;
                  }

                  a {
                     text-decoration: none;
                     color: $nb-dark-blue;
                     font-weight:700;
                     // border: 1px solid $nb-dark-blue;
                     &:hover {
                        color: $nb-green;
                        text-decoration: underline;
                     }
                  }
               }

               h4 {
                  width: 6rem;
                  color: $nb-dark-blue;
                  // padding: 0 1rem;
               }

              
               
            }
         }


      }

   }

  &-occupation {
   padding-bottom: 1rem;
   border-bottom: 1px solid #ffffff;

  }
   
   &-description {
      padding: 1rem;
      

   }

   // &-img {
   //    border: 1px solid #000;
   //    border-radius: .75rem;
   //    margin: 0 1rem;

   // }
}

@include devices(mobile) {
   .about {
      display: flex;
      // margin: 0;
      padding: 0;


      &-item {
         flex-direction:column;
         gap: 1rem;

         &-contents {
            order: 1;
         }
      }
      &-img {
         order: 0;
         padding: 0;
         margin: 0;
      }
   }
}