@keyframes animate {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}
* {
  margin: 0;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}

div.page-title {
  text-align: center;
}

.home .about .contact {
  margin-top: 3rem;
  padding: 1rem 15rem;
}

@media (max-width: 480px) {
  * {
    overflow-y: auto;
  }
}
.navigation-block {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  position: fixed;
  background-color: #449342;
  padding: 1rem 3rem;
  z-index: 9999;
}
.navigation-block-section {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  color: #ffffff;
  position: fixed;
}
.navigation-block-logo {
  font-weight: 700;
  font-size: 1.2rem;
}
.navigation-block-list {
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
  z-index: 999;
}
.navigation-block-list li {
  width: -moz-fit-content;
  width: fit-content;
}
.navigation-block-list li > * {
  text-decoration: none;
  color: #ffffff;
}
.navigation-block-list .icons {
  display: flex;
  gap: 1rem;
}
.navigation-block-menu-icon {
  display: none;
}

@media (max-width: 480px) {
  .navigation-block {
    padding: 0;
    justify-content: space-between;
  }
  .navigation-block-section {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    width: 100%;
    color: #ffffff;
    z-index: 999;
    background-color: #449342;
    padding: 1rem;
  }
  .navigation-block-logo {
    flex: 0 1 75vw;
    font-weight: 700;
    align-self: flex-start;
  }
  .navigation-block-menu {
    flex: 0 1 15vw;
    height: 100%;
  }
  .navigation-block-menu-icon {
    display: flex;
    height: 100%;
  }
  .navigation-block-menu-icon svg {
    min-height: 1.5rem;
  }
  .navigation-block-list {
    display: none;
    background-color: #3E4840;
    padding: 1rem;
    height: 100vh;
    font-size: 2rem;
    transition: 1s all;
    z-index: 1;
  }
  .navigation-block-list li > * {
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
  }
  .navigation-block-list li .icons img {
    min-height: 2rem;
  }
  .active {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    margin: 0;
    padding: 0;
  }
  .active a {
    text-align: center;
  }
  .inactive {
    display: none;
  }
}
.home-landing {
  text-align: center;
  background-color: #449342;
  color: #ffffff;
  display: flex;
  align-items: center;
  height: 70vh;
  box-sizing: border-box;
  overflow: hidden;
}
.home-landing-text {
  z-index: 99;
  position: absolute;
}
.home-landing-shape {
  width: 800px;
  height: 800px;
  border-radius: 50%;
  background-color: rgba(46, 173, 124, 0.4);
  opacity: 0.3;
  position: relative;
  margin-left: -5rem;
  margin-top: -20rem;
  z-index: 0;
}
.home-landing-shape-1 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: rgba(46, 173, 124, 0.4);
  opacity: 0.3;
  position: relative;
  margin-left: 8rem;
  margin-top: 30rem;
  z-index: 0;
}

@media (max-width: 480px) {
  .home-landing {
    height: 100vh;
  }
  .home-landing-text {
    padding: 1rem;
  }
  .home-landing-text h1 {
    margin-bottom: 1rem;
  }
}
.about {
  margin-top: 5rem;
  padding: 2rem 8rem;
}
@media (max-width: 480px) {
  .about {
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
}
.about-item {
  color: #fff;
}
.about-item-contents-bio {
  background-color: #02353c;
  padding: 2rem;
  margin-bottom: 1rem;
}
.about-item-contents-details {
  display: flex;
  gap: 1rem;
}
.about-item-contents-details > .icons {
  display: flex;
  background-color: #ededed;
  padding: 1.5rem;
  align-items: center;
}
@media (max-width: 480px) {
  .about-item-contents-details > .icons:nth-child(1) {
    display: none;
  }
}
.about-item-contents-details > .icons .icon-content {
  display: flex;
  gap: 1rem;
}
@media (max-width: 480px) {
  .about-item-contents-details > .icons .icon-content {
    text-align: center;
  }
}
.about-item-contents-details > .icons .icon-content img {
  width: 2rem;
}
.about-item-contents-details > .icons .icon-content a {
  text-decoration: none;
  color: #02353c;
  font-weight: 700;
}
.about-item-contents-details > .icons .icon-content a:hover {
  color: #449342;
  text-decoration: underline;
}
.about-item-contents-details > .icons h4 {
  width: 6rem;
  color: #02353c;
}
.about-occupation {
  padding-bottom: 1rem;
  border-bottom: 1px solid #ffffff;
}
.about-description {
  padding: 1rem;
}

@media (max-width: 480px) {
  .about {
    display: flex;
    padding: 0;
  }
  .about-item {
    flex-direction: column;
    gap: 1rem;
  }
  .about-item-contents {
    order: 1;
  }
  .about-img {
    order: 0;
    padding: 0;
    margin: 0;
  }
}
.gallery {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  height: 100%;
}
.gallery-item {
  background-color: #ededed;
  padding: 1.5rem;
}
.gallery-item:nth-child(2n) {
  background-color: #fff;
}
.gallery-item-url {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: #000;
  text-align: left;
}
.gallery-item-url-img {
  width: 33%;
}
.gallery-item-url-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;
  text-align: left;
  gap: 1rem;
  text-decoration: none;
}
.gallery-item-url-description h3 {
  color: #449342;
  font-size: 1.7rem;
}
.gallery-item-url-description small {
  font-weight: 700;
}
.gallery-item-url-description .file-icon {
  display: inline-flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border: solid 1px #02353c;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1rem;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #02353c;
}
.gallery-item-url-description .file-icon > * {
  width: -moz-fit-content;
  width: fit-content;
}

@media (max-width: 480px) {
  .gallery {
    flex-direction: column;
    margin-top: 2rem;
    padding: 1rem;
  }
  .gallery-item-url {
    display: flex;
    flex-direction: column;
  }
  .gallery-item-url-img {
    width: 100%;
  }
  .gallery-item-url-description {
    text-align: center;
    align-items: center;
    gap: 1rem;
  }
  .gallery-item-url-description p {
    display: none;
  }
  .gallery-item-url-description .file-icon {
    display: inline-flex;
    text-align: left;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    border: solid 1px #3e4840;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1rem;
    border-radius: 0.25rem;
  }
  .gallery-item-url-description .file-icon > * {
    width: -moz-fit-content;
    width: fit-content;
  }
}
.contact {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  padding: 1rem;
  background-color: #02353c;
  color: #ffffff;
  margin-bottom: 3rem;
  gap: 3rem;
}
@media (max-width: 480px) {
  .contact {
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }
}
.contact .contact-info {
  padding: 2rem;
  font-size: 1.3rem;
}
.contact .contact-form {
  padding: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
}
.contact .contact-form form {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.contact .contact-form form > * {
  margin-bottom: 1rem;
}
.contact .contact-form form label {
  font-size: 1.2rem;
  font-weight: 800;
}
.contact .contact-form form input {
  padding: 0.55rem;
  background-color: transparent;
  color: #449342;
  border: none;
  border-bottom: rgb(255, 255, 255) solid 1px;
}
.contact .contact-form form input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.contact .contact-form form input::placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.contact .contact-form form input[type=submit] {
  background-color: transparent;
  border-radius: 0.7rem;
  border: solid 2px #fff;
  width: 25%;
  padding: 0.65rem;
  font-size: 1.1rem;
  color: #fff;
}
.contact .contact-form form input[type=submit]:hover {
  background-color: #449342;
}
@media (max-width: 480px) {
  .contact .contact-form form input[type=submit] {
    width: 100%;
  }
}
.contact .contact-form form textarea {
  padding: 0.55rem;
  background-color: transparent;
  border: none;
  border-bottom: solid 2px #ffffff;
}
.contact .contact-form form textarea::-moz-placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.contact .contact-form form textarea::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.footer {
  bottom: 0;
  background-color: #449342;
  color: #ffffff;
  padding: 1rem;
  text-align: center;
}/*# sourceMappingURL=main.css.map */