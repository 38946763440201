.navigation-block {
   display: flex;
   flex-wrap:nowrap;
   flex-direction: row;
   width: 100%;
   position: fixed;
   background-color: $nb-green;
   padding: 1rem 3rem;
   z-index: 9999;


   &-section {
      display: flex;
      flex-wrap:nowrap;
      flex-direction: row;
      width: 100%;
      color: #ffffff;
      position: fixed;
      // padding:0rem 3rem;

   }

   &-logo {
      font-weight: 700;
      font-size: 1.2rem;
   }

   &-list {
      display: flex;
      justify-content: flex-end;
      gap: 2rem;
      // margin-right: 3rem;
      z-index: 999;
      
      li{
         width: fit-content;
         > * {
            text-decoration: none;
            color:#ffffff;

         }
      }

      .icons{
         display: flex;
         gap: 1rem;
      }
    
   }

   &-menu-icon {
      display: none;
   }
  
}

@include devices(mobile){
   .navigation-block{
      padding: 0;
      justify-content: space-between;
      

      &-section {
         display: flex;
         flex-wrap:nowrap;
         flex-direction: row;
         width: 100%;
         color: #ffffff;
         z-index: 999;
         background-color: $nb-green;
         padding: 1rem;


      }
   
      &-logo{
         flex: 0 1 75vw;
         font-weight: 700;
         align-self: flex-start;
      }
      &-menu{
         flex: 0 1 15vw;
         height: 100%;
         &-icon{
            display: flex;
            height: 100%;
            svg {
               min-height: 1.5rem;

            }
         }
        
      }
      &-list {
         display: none;
         background-color: #3E4840;;
         padding: 1rem;
         height: 100vh;
         font-size: 2rem;
         transition: 1s all;
         z-index: 1;  
        li {

         > * {
            text-align: center;
            width: fit-content;

         }
         .icons {
            img {
               min-height: 2rem;

            }
         }
        }
      }
     
      
   
   
  
   }
  
   .active {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      // height: 100vh;
      // width: 100vw;
      margin: 0;
      padding: 0;

      a {
         text-align: center;
      }
   }  
   .inactive {
      display: none;
   }
}