@import "./media-queries";

.gallery {
   display: flex;
   flex-direction:column;
   gap: 1rem;
   padding: 1rem;
   height: 100%;
   &-item {
      // background-color:  rgba(68, 148, 66, .7);
      background-color: $nb-gray;
      padding: 1.5rem;

      &:nth-child(2n) {
         background-color: #fff;
      }
      &-url {
         display: flex;
         flex-direction: row;
         text-decoration:none;
         color: #000;
         text-align: left;

         &-img{
            width:33%;
         
         }

         &-description {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 1.5rem;
            text-align: left;
            gap: 1rem;
            text-decoration: none;
            // max-width: 70%;
            h3{
               color: $nb-green;
               font-size: 1.7rem;
            }
            small {
               font-weight:700;
            }
            .file-icon {
               display: inline-flex;
               text-align: left;
               align-items: center;
               justify-content: center;
               gap: 1rem;
               border: solid 1px $nb-dark-blue;
               width: fit-content;
               padding: 1rem;
               border-radius: .25rem;
               text-decoration: none;
               color:$nb-dark-blue;


                > * {
                  width: fit-content;
                  
               }
            }
         }
        
      }
      
   }
}

@include devices(mobile){
   .gallery {
      flex-direction: column;
      margin-top: 2rem;
      padding: 1rem;

      &-item {
         &-url {
            display: flex;
            flex-direction: column;
            

            &-img {
               width: 100%;
            }

            &-description {
               text-align: center;
               align-items: center;
               gap: 1rem;
               p {
                  display: none;
               }

               .file-icon {
                  display: inline-flex;
                  text-align: left;
                  align-items: center;
                  justify-content: center;
                  gap: 1rem;
                  border: solid 1px #3e4840;
                  width: fit-content;
                  padding: 1rem;
                  border-radius: .25rem;

                   > * {
                     width: fit-content;
                  }
               }
            }
         }
      }
   
   }
}