.home {
   &-landing {
      text-align: center;
      background-color:$nb-green;
      color: #ffffff;
      display: flex;
      align-items: center;
      height: 70vh;
      box-sizing: border-box;
      overflow: hidden;

      &-text {
         z-index: 99;
         position: absolute;
      }

      &-shape{
         width: 800px;
         height: 800px;
         border-radius: 50%;
         background-color: rgba(46, 173, 124, .4);
         opacity: .3;
         position: relative;
         margin-left: -5rem;
         margin-top: -20rem;
         z-index: 0;

         &-1 {
            width: 200px;
            height: 200px;
            border-radius: 50%;
            background-color: rgba(46, 173, 124, .4);
            opacity: .3;
            position: relative;
            margin-left: 8rem;
            margin-top: 30rem;
            z-index: 0;

         }
      }
   }
}

@include devices(mobile){
   .home{
      &-landing{
         height: 100vh;
         &-text {
            padding: 1rem;
            h1 {
               margin-bottom: 1rem;
            }
         }
      }
   }
}